<template>
    <topBanner/>
    <shiprepairs/>
</template>

<script>
import topBanner from '../components/common/topBanner.vue'
import shiprepairs from '../components/shiprepairs.vue'
export default {
    components: {
        topBanner,
        shiprepairs
    },
    data(){
        return{}
    }
}
</script>